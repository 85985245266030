@import url(https://fonts.googleapis.com/css?family=Open+Sans:600|Work+Sans:400,500,600,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

 
.outer {
  width: 100%;
  height: 100%;
}
.css-binzgt {
  margin-top: 6px!important;
}
body{ 
  background: linear-gradient(to right, #39c0b3 ,#c9bc51);
 /* background: linear-gradient(135deg, rgba(34,193,195,1) 0%,     rgba(253,187,45,1) 100%);
 */ font-family: "work sans";
}

.outer{
  background: #f5f5f5;
  padding:10px;
  display: flex;
  margin: auto;
  background: #f5f5f5;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2); 
  border-radius: 15px;
  transition: all 0.3s;
}
 

